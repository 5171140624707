addEventListener("trix-initialize", function (event) {
  new RichText(event.target)
})

class RichText {
  constructor(element) {
    this.element = element
    this.insertiFrameButton()
    this.insertDialogiFrame()
  }

  insertiFrameButton() {
    this.buttonGroupFileTools.insertAdjacentHTML("beforeend", this.iframeButtonTemplate)
  }

  insertDialogiFrame() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.dialogiFrameTemplate)
  }

  get buttonGroupFileTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=file-tools]")
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]")
  }

  get toolbarElement() {
    return this.element.toolbarElement
  }

  get iframeButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-iframe" data-trix-action="x-iframe" title="iFrame" tabindex="-1">iFrame</button>'
  }

  get dialogiFrameTemplate() {
    return `
      <div class="trix-dialog trix-dialog--link" data-trix-dialog="x-iframe" data-trix-dialog-attribute="x-iframe">
        <div class="trix-dialog__link-fields">
          <input name="x-iframe" id="iframe-input" class="trix-input trix-input--dialog" placeholder="iframeコードを入力する" aria-label="iFrame" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-method="hideDialog" onclick="
              const trix = document.querySelector('trix-editor');
              const iframeText = document.querySelector('#iframe-input');
              trix.editor.insertString(iframeText.value);
              trix.editor.insertLineBreak();
            ">Add</button>
          </div>
        </div>
      </div>
    `
  }
}
